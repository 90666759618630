.nominal-percent-input {
  .mantine-SegmentedControl-label {
    line-height: 2;
  }
  .mantine-NumberInput-wrapper input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .mantine-Select-wrapper input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-size: 16px;
  }
  .mantine-Grid-col {
    padding: 0;
  }
}
