@import "lib/frontend/cm/colors";

.interval-editor-container {
  & .interval-editor-slider {
    & .rc-slider-handle {
      width: 16px;
      height: 16px;
      margin-top: -6px;

      border-width: 4px;
      opacity: 1;
    }

    & .rc-slider-handle-dragging {
      box-shadow: none;

      // ligthen $color-brand
      border-color: lighten($color-brand, 30%) !important;
    }
  }
}
