@import "~/lib/frontend/cm/colors";

.MuiSvgIcon-root.award-letter-row-icon {
  color: $color-green;
  width: 40px;
  height: 30px;
}

.MuiSvgIcon-root.award-letter-info-icon {
  color: $color-gold;
  width: 16px;
  height: 16px;
  position: relative;
  bottom: 2px;
  margin-left: 6px;
}
.MuiSvgIcon-root.award-letter-row-icon.color-gold {
  color: $color-gold;
}

.employee-award-letter-container {
  max-width: 800px;
}
