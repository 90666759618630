.root {
  transition:
    background-color 300ms ease,
    color 300ms ease,
    border-color 300ms ease;
}

.section {
  &[data-position="left"] {
    margin-inline-end: var(--mantine-spacing-md);
  }

  &[data-position="right"] {
    margin-inline-start: var(--mantine-spacing-md);
  }
}
