.root {
  padding-right: var(--mantine-spacing-md);
  padding-left: var(--mantine-spacing-md);
  border-radius: var(--mantine-radius-sm);

  &:where([data-active]) {
    --nl-bg: var(--mantine-color-gray-2);
  }
}
.label {
  color: var(--mantine-color-gray-7);
}
