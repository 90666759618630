.items {
  background: #fff;
  border-radius: 0.5rem;
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.05),
    0px 10px 20px rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.8);
  font-size: 0.9rem;
  overflow: hidden;
  overflow-y: scroll;
  padding: 0.2rem;
  position: relative;
  max-height: 500px;
}

.item {
  border: 1px solid transparent;
  border-radius: 0.4rem;
  margin: 0;
}

.is-selected {
  border-color: #000;
}
