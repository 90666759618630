@charset "utf-8";

$color-primary: #caebe6;
$color-primary-dark: #7fc7bd;

/* $button-send-color: #343a40;
$button-send-bg-color: #a9e34b; */

@import "@chatscope/chat-ui-kit-styles/themes/default/variables";

@import "@chatscope/chat-ui-kit-styles/themes/default/main";
