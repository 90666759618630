@import "~/lib/frontend/cm/colors";

.MuiButton-root.slope-button.button-circle-plus {
  color: $color-gray-3;
  padding: 0;
  margin: 2px 0 0 0;
  min-width: auto;
  height: 38px;
  width: 38px;
  .MuiButton-label {
    width: auto;
  }
  svg {
    position: relative;
    left: -14px;
  }
}

.ag-cell-align-right {
  .ag-header-cell-label {
    justify-content: flex-end;
  }
  .ag-header-cell-menu-button {
    display: block;
    margin-left: 2px;
  }
}
.ag-cell.ag-cell-value.justify-end {
  padding-right: 32px;
}
