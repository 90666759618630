.currency-percent-input {
  border-color: transparent;
  & label.Mui-focused {
    color: transparent;
  }
  & .MuiInput-underline:after {
    border-bottom-color: transparent;
  }
  & .MuiOutlinedInput-root {
    border-radius: 0;
    border-color: transparent;
    & fieldset {
      min-width: unset;
      border-color: transparent !important;
      outline: none;
    }
    &:hover fieldset {
      border-color: transparent !important;
    }
    &.Mui-focused fieldset {
      border-color: transparent !important;
    }
    &.Mui-disabled fieldset {
      border-color: transparent !important;
      border-radius: 0;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
