.root {
  &[data-variant="buttons"] {
    --sc-shadow: none;
    background-color: inherit;
    overflow: visible;

    :global(.mantine-SegmentedControl-indicator) {
      display: none;
    }

    :global(.mantine-SegmentedControl-control) {
      border-radius: var(--mantine-radius-sm);
      border: var(--sc-color-inactive-border);
      background-color: var(--sc-color-inactive-bg);
      margin-right: var(--mantine-spacing-md);

      :global(.mantine-SegmentedControl-label) {
        color: var(--sc-color-inactive);
        font-size: var(--mantine-font-size-xs);
        padding: 8px 12px;
      }

      @mixin hover {
        &:hover {
          background-color: var(--sc-color-hover);
          border: var(--sc-color-active-border);
        }

        &:active {
          transform: translateY(1px);
        }
      }

      &[data-active="true"] {
        background-color: var(--sc-color-active-bg);
        border: var(--sc-color-active-border);
      }

      &[data-orientation="vertical"] {
        margin-right: 0;
        margin-bottom: var(--mantine-spacing-md);

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }

      &::before {
        display: none;
      }
    }

    :global(.mantine-SegmentedControl-label) {
      padding: var(--mantine-spacing-md) var(--mantine-spacing-lg);
    }
  }
}
