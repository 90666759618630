.slope-note-message-list {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 160px;
}

.slope-note-message-list::-webkit-scrollbar {
  background-color: white;
  border-radius: 10px;
  width: 20px;
}

.slope-note-message-list::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 10px;
  border: 5px solid white;
}

.slope-note-message-list::-webkit-scrollbar-thumb:vertical {
  height: 30px;
  width: 20px;
}
