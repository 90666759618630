.root {
  &[data-variant="default"] {
    --tabs-list-border-width: 1px;
  }
}

.tabLabel {
  color: var(--mantine-color-gray-5);

  [data-active] & {
    color: var(--mantine-color-gray-8);
  }
}

.tab {
  padding: var(--mantine-spacing-md) 0;

  &[data-variant="outline"] {
    margin-right: var(--mantine-spacing-sm);
  }

  margin-right: var(--mantine-spacing-xl);
  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    .tabLabel {
      color: var(--mantine-color-gray-8);
    }
  }

  &:where([data-active]) {
    border-bottom-width: calc(var(--tab-border-width) + 1px);
    .tabLabel {
      /* When active, the label bumps up a little bit because of the border size,
         so we need to adjust it back down */
      position: relative;
      top: 1px;
    }
  }

  &:active {
    .tabLabel {
      transform: translateY(calc(0.0625rem * var(--mantine-scale)));
    }
  }

  &[data-variant="outline"] {
    padding-left: var(--mantine-spacing-md);
    padding-right: var(--mantine-spacing-md);

    &[data-active="true"] {
      .tabLabel {
        :global(.mantine-Text-root) {
          font-weight: bold;
          color: darken(var(--tabs-color), 0.3);
        }
      }
    }
  }
}
