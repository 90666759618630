// this becomes unnecessary if we correctly switch to using postcss processing and can use tailwind's @apply methods

$color-brand: #26524c;
$color-brand-hover: #5956e9;
$color-brand-light: #e6e5ff;
$color-green: #33a93f;
$color-red: #eb5757;
$color-gold: #c3a148;
$color-pale-gold: #fcf8e6;
$color-gray-1: #2f3234;
$color-gray-2: #484c51;
$color-gray-3: #797d83;
$color-gray-4: #b4b8b7;
$color-gray-5: #d3dbe0;
$color-gray-6: #eaedf3;
$color-gray-7: #f5f6f9;
$color-white: #ffffff;
$color-black: #000000;

$color-gray-200: #fafafc;
$color-gray-300: #f2f2f7;
$color-gray-400: #dadae0;
$color-gray-600: #85858c;
$color-gray-700: #63636b;
$color-gray-900: #1c1c1f;
