@import "~/lib/frontend/cm/colors";

.MuiFormControl-root.slope-input {
  min-width: 245px;
  border-radius: 8px;

  .MuiInputBase-root {
    border-radius: 4px;
    height: 34px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: $color-gray-5;
  }

  .MuiSelect-icon {
    margin-top: 3px;
  }
}
